import React from "react";
import Helmet from 'react-helmet';

/**
 * Layouts
 */
import Layout from '@layouts/default';

/**
 * Components
 */
import Intro from '@components/intro/intro';
import About from '@components/about/about';
import SeamlessExperience from '@components/seamless-experience/seamless-experience';
import PropertyControl from '@components/property-control/property-control';
import Reviews from '@components/reviews/reviews';
import BookVideo from '@components/book-video/book-video';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import { graphql } from 'gatsby'
import get from 'lodash/get'
import SEO from "../components/seo"

class Home extends React.Component {

  render() {

    const Modules = get(this, 'props.data.strapiArticles.Add_Modules')
    const Global_Modules = get(this, 'props.data.strapiArticles.Global_Module')
    const Page = get(this, 'props.data.strapiArticles')


    return (
      <Layout>
        <Helmet 
          bodyAttributes={{
            class: 'homepage'
          }}
        />
        <SEO title={Page.Meta_Title} description={Page.Meta_Description} />

        {Modules.map((Add_Modules, i) => {
          return (
            <>
              {Add_Modules.Banner_Title != null &&
                <Intro Title={Add_Modules.Banner_Title} Content={Add_Modules.Content} CTA_1_Label={Add_Modules.CTA_1_Label} CTA_1_URL={Add_Modules.CTA_1_URL} CTA_2_Label={Add_Modules.CTA_2_Label} CTA_2_URL={Add_Modules.CTA_2_URL} Show_Google_Rating={Add_Modules.Show_Google_Rating} Upload_Video={Add_Modules.Upload_Video} Banner_Image={Add_Modules.Banner_Image} Embed_Video_URL={Add_Modules.Embed_Video_URL} height="1080px" Custom_CSS="intro_style_1"  size="930x1080"/>
              }
              
              {Add_Modules.Services != null &&
                <About Title={Add_Modules.Title} Content={Add_Modules.Content} Services={Add_Modules.Services} CTA_Label={Add_Modules.CTA_Label} CTA_URL={Add_Modules.CTA_URL}/>
              }

              {Add_Modules.Service_Title != null &&
              <>
                {Add_Modules.Choose_Style == "Style_1_White_Background" &&
                  <SeamlessExperience Title={Add_Modules.Service_Title} Content={Add_Modules.Content} Embed_Video_URL={Add_Modules.Embed_Video_URL} CTA_Label={Add_Modules.CTA_Label} CTA_URL={Add_Modules.CTA_URL} Upload_Video={Add_Modules.Upload_Video} Image={Add_Modules.Image} size="890x600"/>
                }

                {Add_Modules.Choose_Style == "Style_2_Grey_Background" &&
                  <PropertyControl Title={Add_Modules.Service_Title} Content={Add_Modules.Content} Embed_Video_URL={Add_Modules.Embed_Video_URL} CTA_Label={Add_Modules.CTA_Label} CTA_URL={Add_Modules.CTA_URL} Upload_Video={Add_Modules.Upload_Video} Image={Add_Modules.Image}/>
                }
              </>
              }
              
            </>
          )
        })}

        {Global_Modules.map((Global_Modules, i) => {
          return (
            <>
              
              {Global_Modules.Please_Choose == "Reviews" &&
                <Reviews /> 
              }
              
              {Global_Modules.Please_Choose == "Book_A_Call" &&
                <BookVideo /> 
              }
              
            </>
          )
        })}

      <Breadcrumbs Page_1="Estate Agents in West Yorkshire"/>
      </Layout>
    )
  }
}

export default Home;


export const pageQuery = graphql`
  query HomePageQuery {

  strapiArticles(id: {eq: "Articles_5f56025eaa6b173dc7af82cb"}) {
    Pagename
    id
    Meta_Description
    Meta_Title
    Add_Modules {
      Content
      Title
      CTA_1_Label
      CTA_1_URL
      CTA_2_Label
      CTA_2_URL
      CTA_URL
      CTA_Label
      Services_Links_Title
      Show_Google_Rating
      Upload_Video {
        publicURL
      }
      Services {
        Label
        URL
        Thumbnail_Image {
          publicURL
        }
      }
      Banner_Image {
        internal {
          description
        }
        publicURL
      }
      Choose_Style
      Image {
        internal {
          description
        }
        publicURL
      }
      Service_Title
      Services_Links {
        URL
        Label
      }
      Speak_with {
        Name
        Email
        Image {
          publicURL
        }
        Phone
      }
      Banner_Title
      Embed_Video_URL
    }
    Global_Module {
      Please_Choose
    }
  }

  }
`
